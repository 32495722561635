<template>
  <b-card> 
    <b-row>
      <b-col cols="12">
        {{item.title}}
      </b-col>

      <b-col cols="12" class="text-center">
        start
      </b-col>
      <b-col cols="12" class="text-center">
        +
      </b-col>

     <template v-for="(step, stepIdx) in item.steps" >
      <b-col cols="12"  class="text-center" :key="stepIdx">
        {{step.title}}
      </b-col>
      <b-col cols="12"  class="text-center" :key="stepIdx">
        +
      </b-col>
     </template>
      
      <b-col cols="12" class="text-center">
        end
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  name: "LibFlowEdit",
  components: {
    BCard,
    BRow,
    BCol,
  },

  data() {
    return {
      id: this.$route.params.id,

      item: null,
    };
  },

  beforeMount() {
    this.getItem();
  },

  methods: {
    getItem() {
      if (this.id == "new") {
        const item = {
          //TODO: set default values
        };
        this.item = item;
      } else {
        this.$http.get("/api/lib-flow/" + this.id).then((res) => {
          // console.log(res.data);
          this.item = res.data.item;
        });
      }
    },
  },
};
</script>